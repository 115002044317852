import React, { FC, useEffect } from 'react';
import { PageLayout, PageHeading } from 'components';
import { graphql } from 'gatsby';

const Members: FC = ({ data }: any) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://ptminder.com/website/extform/?guid=m56csePT1eLCyA==&st[]=forgot_password&st[]=keep_logged';
    script.async = true;

    document.head.appendChild(script);
  });
  const { banner } = data.strapiGlobal;
  const {
    title,
    seo: { metaDescription, metaTitle },
  } = data.strapiMembersLogin;

  return (
    <PageLayout
      title={title}
      metaTitle={metaTitle ?? title}
      description={metaDescription}
      hasContainerClass
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
    >
      <PageHeading title="Members Login" level={1} />
      <div id="ptminder-client-login" />
    </PageLayout>
  );
};

export default Members;

export const query = graphql`
  query MemberLoginQuery {
    strapiMembersLogin {
      title
      seo {
        metaTitle
        metaDescription
      }
    }
    strapiGlobal {
      banner: defaultBanner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
